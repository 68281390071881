import {Link} from '@remix-run/react';
import type {NavLinkProps} from 'react-bootstrap';
import {Nav} from 'react-bootstrap';

/**
 * Combines Nav.Item, Nav.Link from react-bootstrap with Link from remix to make
 * sure we get all the right props in all the right places without requiring
 * consumers to repeat themselves.
 * */
export const NavLink = ({href, ...props}: NavLinkProps) => {
  const isExternal = href?.startsWith('http');

  return (
    <Nav.Item>
      {isExternal ? (
        <Nav.Link href={href} {...props} />
      ) : (
        <Nav.Link as={Link} to={href} href={href} {...props} />
      )}
    </Nav.Item>
  );
};
